import React from 'react'
import "./TerimaKasih.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { NavLink as Link, NavLink} from "react-router-dom"


function TerimaKasihPD() {
    const login = JSON.parse(localStorage.getItem("loginData"))
    const biodata = JSON.parse(localStorage.getItem("biodata"))
    return (
        <div className='thanksWrapper'>
            <h1>Terima Kasih atas Pengisian Data</h1>
            <h1>yang Anda lakukan</h1>
            <div className='thanksDetail'>
                <div>
                    <div style={{fontWeight:800}}>
                        Pengisi:
                    </div>
                    <div>
                        {login.username}
                    </div>
                </div>
                <div>
                    <div style={{fontWeight:800}}>
                        Data yang di masukkan:
                    </div>
                    <div>{biodata.namaLengkap}</div>
                    <div>{biodata.nip}</div>
                    <div>{biodata.jabatan}</div>
                    <div>{login.school.school_name}</div>
                </div>
            </div>
            <p>
            Dengan ini dinyatakan  bahwa Data yang diisikan adalah benar, tidak ada manipulasi penambahan maupun pengurangan. Data yang diisikan menjadi tanggungjawab Pengisi Data terhadap Dinas Pendidikan dan Kebudayaan Kabupaten Madiun, yang akan digunakan sebagaimana mestinya.
            </p>
            <NavLink to ='/dashboard' style={{cursor:"pointer"}}>
            <button><FontAwesomeIcon icon={faThumbsUp}style={{marginRight:"20%"}}/>Selesai</button>
            </NavLink>
        </div>
    )
}

export default TerimaKasihPD
