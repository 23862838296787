import { React, useState, useEffect } from 'react'
import './jadwal.css';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'

export default function Jadwal() {

    const navigate = useHistory()
    const DataJadwal = JSON.parse(localStorage.getItem("Jadwal"))
    const daftarSekolah = JSON.parse(localStorage.getItem('daftarSekolah'))
    const login = JSON.parse(localStorage.getItem("loginData"))
    const [sekolah, setSekolah] = useState([
    ])


    useEffect(() => {
        if(DataJadwal != null){
            setJadwal(DataJadwal)
        }
        console.log (mapel)
    }, [])

    useEffect(() => {
        console.log(login.token)
        var config = {
            method: 'get',
            url: 'https://siapngajar-be-y2fugkloba-et.a.run.app/api/v1/schools',
            headers: { 
              'Authorization': 'Bearer '+ (login.token)
            },
          };
          axios(config)
          .then(function (response) {
            setSekolah(response.data.data)
          })
          .catch(function (error) {
            console.log(error);
          });
    }, [])

    const [input, setInput] = useState({
            id:0,
            day: "Senin",
            subject: "Pimpinan",
            start: "",
            endActivity: "",
            location: 0
    })

    const [listSekolah, setListSekolah] = useState(daftarSekolah)
    const [mapel, setMapel] = useState([
        "Kepala OPD",
        "Sekretaris",
        "Bidang Umum dan Kepegawaian",
        "Kelompok Jabatan Fungsional",
        "Bidang Kebudayaan",
        "Bidang Ketenagaan",
        "Bidang Paud dan Dikmas",
        "Bidang Pembina SD",
        "Bidang Pembina SMP",
        
    ])

    const [jadwal, setJadwal] = useState([
    ])

    const jadwalChange = e => {
        setInput({
            ...input,
            [e.target.name]: e.target.value
        })
    }

    const onTambah = e => {
        setJadwal([
            ...jadwal,
            input
        ])
        setInput({
            ...input,
            id : input.id + 1
        })
        console.log(jadwal)
    }
    const onClick = e => {
        e.preventDefault()
        localStorage.setItem('Jadwal',JSON.stringify(jadwal))
        navigate.push('/InputData/Dinas/biodataPD/jadwalPD/tinjauanPD')
    }
    const onClickBack = e => {
        e.preventDefault()
        navigate.push('/InputData/Dinas/biodataPD')
    }
    const onDelete = (e) =>{
         const id = parseInt(e.target.name)
         const newJadwal = jadwal.filter((jadwal) => jadwal.id !== id)
         setJadwal(newJadwal)
    }

    return (
        <div className='Jadwal-Wrapper'>
            <div className='judulJadwal-Wrapper'>
                <h1 className='judulJadwal-Bold'>Jadwal</h1>
                <h1 className='judulJadwal-Thin'>Dinas</h1>
            </div>
            <div className='jadwalCard-Wrapper'>
                <form onChange={jadwalChange}>
                <div className='jadwal-DoubleInput'>
                    <div>
                        <p>Status Hari</p>
                        <select name ="day">
                            <option value="Senin">Senin</option>
                            <option value="Selasa">Selasa</option>
                            <option value="Rabu">Rabu</option>
                            <option value="Kamis">Kamis</option>
                            <option value="Jumat">Jumat</option>
                            <option value="Sabtu">Sabtu</option>
                            <option value="Minggu">Minggu</option>
                        </select>
                    </div>
                    <div>
                        <p>Bidang</p>
                        <select name='subject'>
                            {mapel.map (item =>
                            <option value={item}>{item}</option>
                            )}
                            
                        </select>
                    </div>
                </div>
                <div className='jadwal-DoubleInput'>
                    <div>
                        <p>Jam Masuk</p>
                        <input name = "start" type={"time"} />
                    </div>
                    <div>
                        <p>Jam Pulang</p>
                        <input name='end_activity' type={"time"} />
                    </div>
                    <div className='jadwalKelas'>
                        <p>Lokasi</p>
                        <select name='location'>
                            <option >pilih lokasi</option>
                            <option value={login.school.school_id}>{login.school.school_name}</option>    
                            {sekolah.map(item =>
                             <option value={item.id}>{item.name}</option>
                                )}
                        </select>
                    </div>
                </div>
                </form>
            </div>
            <div className='tambahJadwal' onClick={onTambah} style={{ cursor: "pointer" }}>
                + Tambahkan Jadwal
            </div>
            <table className = "tabelJadwal">
                <th>
                    Hari
                </th>
                <th>
                    Waktu
                </th>
                <th>
                    Mata Pelajaran
                </th>
                <th>
                    Lokasi
                </th>
                <th>
                    Status
                </th>
                <th>
                    Aksi
                </th>
                {jadwal.map(item =>
                    <tr>
                        <td>
                            {item.day}
                        </td>
                        <td>
                            {item.start}-{item.end_activity}
                        </td>
                        <td>
                            {item.subject}
                        </td>
                        <td>
                            {item.location}
                        </td>
                        <td>
                            {item.status}
                        </td>
                        <td><button name={item.id} onClick={onDelete}>
                    <FontAwesomeIcon icon={faTrash} /> Hapus
                    </button></td>
                    </tr>    
                )}
            </table>
            {/* {jadwal.map(jadwal =>
                <div className='jadwalCard-Wrapper2'>
                    <div className='jadwal-DoubleInput'>
                        <div>
                            <p>Status Hari</p>
                            <select>
                                <option value={jadwal.hari}>{jadwal.hari}</option>
                            </select>
                        </div>
                        <div>
                            <p>Mata Pelajaran</p>
                            <select>
                                <option value={jadwal.mapel}>{jadwal.mapel}</option>
                            </select>
                        </div>
                    </div>
                    <div className='jadwal-DoubleInput'>
                        <div>
                            <p>Jam Mulai</p>
                            <input type={"time"} value={jadwal.jam} readOnly/>
                        </div>
                        <div>
                            <p>Jam Selesai</p>
                            <input type={"time"} value={jadwal.jamSelesai}readOnly/>
                        </div>
                        <div className='jadwalKelas'>
                            <p>Lokasi Mengajar</p>
                            <select>
                                <option value={jadwal.lokasi}>{jadwal.lokasi}</option>
                            </select>
                        </div>
                    </div>
                    <div className='delete'>
                    <button name={jadwal.id} onClick={onDelete}>
                    <FontAwesomeIcon icon={faTrash} /> Hapus
                    </button>
                    </div>
                </div>
            )} */}
            <div className='jadwalButton-Wrapper'>
                <button onClick={onClick}style={{cursor:"pointer"}}>Simpan dan Lanjut</button>
                <h1 onClick={onClickBack}style={{cursor:"pointer"}}>←Kembali</h1>
            </div>
        </div>
    )
}


