import {React,useEffect,useState} from 'react'
import './InputGuru.css'
import axios from 'axios'
import sdIcons from '../../../assets/inputGuru/sdIcon.svg'
import smpIcons from '../../../assets/inputGuru/smpIcon.svg'
import { useHistory } from "react-router-dom";

function InputGuru() {

    const navigate = useHistory()
    const login = JSON.parse(localStorage.getItem("loginData"))
    const initialData = JSON.parse(localStorage.getItem("sekolah"))
    const [sekolah, setSekolah] = useState([
    ])
    const [status, setStatus] = useState({
        initialSekolah :"Pilih Sekolah",
        school : "SD"
    })
    const [input, setInput] =  useState ({
        
    })
    const [selected, setSelected] = useState ({

    })

    useEffect(() => {
        for(let i = 0; i < sekolah.length;i++){
            if (input.id == sekolah[i].id){
                setSelected(
                    sekolah[i]
                )
            }
        }
    }, [input])
    const [smp, setSMP] = useState([])
    const [sd, setSD] = useState([])
    useEffect(() => {
        if (initialData != null) {
            setStatus({
                ...status,
                initialSekolah: (initialData.name)
            })
        }
    }, [])



    useEffect(() => {
        if (status.school == "SD"){
            setSekolah(sd)
        }else if (status.school == "SMP"){
            setSekolah(smp)
        }
    }, [status])
    useEffect(() => {
        console.log(login.token)
        var config = {
            method: 'get',
            url: 'https://siapngajar-be-y2fugkloba-et.a.run.app/api/v1/schools',
            headers: { 
              'Authorization': 'Bearer '+ (login.token)
            },
          };
          axios(config)
          .then(function (response) {
            setSekolah(response.data.data)
            for (let i = 0; i<response.data.data.length; i++){
                if (response.data.data[i].school_type == "SD"){
                    setSD(oldsd =>[
                        ...oldsd,
                        response.data.data[i]
                    ])
                } else if (response.data.data[i].school_type == "SMP"){
                    setSMP(oldSmp =>[
                        ...oldSmp,
                        response.data.data[i]
                    ])
                }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }, [])

    const handleDropdown = e =>{
        setInput({
            ...input,
            id: e.target.value,
        })
        console.log(input)
    }

    const handleChange = e =>{
        setStatus({
            ...status,
            [e.target.name]: e.target.value
        })
        console.log(status)
    }
    
    const onClick = e =>{
        // e.preventDefault()
        localStorage.setItem("sekolah",JSON.stringify(selected))
        localStorage.removeItem('biodata')
        localStorage.removeItem('Jadwal')
        localStorage.setItem("daftarSekolah",JSON.stringify(sekolah))
        navigate.push('./Guru/biodataDiri')
        // console.log(initialData.name)
    }
    return (
        <div className='inputGuru-Wrapper'>
            <h1>Jenjang Sekolah</h1>
            <form onChange = {handleChange}>
            <div className='radioWrapper'>
                <div className='iconContainer'>
                    <img src={sdIcons} alt='' />
                    <div><input type="radio" value="SD" name="school" /></div>
                    <p>Sekolah Dasar (SD)</p>
                </div>
                <div className='iconContainer'>
                    <img src={smpIcons} alt='' />
                    <div><input type="radio" value="SMP" name="school"  /></div>
                    <p>Sekolah Menengah Pertama (SMP)</p>
                </div>
            </div>
            </form>
            <h1>Nama Sekolah</h1>
            <select name="sekolah" placeholder='test' onChange={handleDropdown}>
                <option value={status.initialSekolah}>{status.initialSekolah}</option>
                {sekolah.map (item =>
                <option value={item.id}>{item.name}</option>
                )}
            </select>
            <div> <button onClick={onClick} style={{cursor:"pointer"}}>Simpan dan Lanjut</button></div>
        </div>

    )
}

export default InputGuru
