import React from 'react'
import { NavLink as Link, NavLink} from "react-router-dom"
import './navBar.css'
import pemkab from '../../assets/logo/pemkab.png'
import Dashboard from '../../assets/navBar/Dashboard.svg'
import input from '../../assets/navBar/input.svg'
import tinjau from '../../assets/navBar/tinjau.svg'
import pengawas from '../../assets/navBar/pengawas.svg'
import kepsek from '../../assets/navBar/kepsek.svg'
import guru from '../../assets/navBar/guru.svg'
import logout from '../../assets/navBar/logout.svg'
import profile from '../../assets/navBar/profile.svg'
import { useHistory } from "react-router-dom";

function NavBar() {

    const navigate = useHistory()

    const onClickInput = e =>{
        e.preventDefault()
        navigate.push('/InputData')
    }
    const onClickDashboard = e =>{
        e.preventDefault()
        navigate.push('/dashboard')
    }
    const onClickGuru = e =>{
        e.preventDefault()
        navigate.push('/InputData/Guru')
    }
    const onLogout = e =>{
        e.preventDefault()
        localStorage.clear()
        navigate.push('/')
    }
    const clickHandle = event =>{
        localStorage.removeItem('biodata')
        localStorage.removeItem('Jadwal')
    }
         
    return (
        <div className='navBar-Wrapper'>
            <div className='judul'>
                <img src={pemkab} alt="" />
                <div >
                    <h1>DISPENDIKBUD</h1>
                    <h1>Kab.Madiun</h1>
                </div>
            </div>
            <div className='navBar-Menu'>
                <div>Menu</div>
                <NavLink exact to='/dashboard' className='menu-Row'  activeClassName='linkActive'>
                    <img src={Dashboard} />
                    <div>Dashboard</div>
                </NavLink>
                <NavLink to='/InputData' className='menu-Row'  activeClassName='linkActive'>
                    <img src={input} />
                    <div>Input Data</div>
                </NavLink>


                <div className='menuChild'>    
                    <NavLink to='/InputData/Dinas/biodataPD' className='menu-Row'  onClick={clickHandle} activeClassName='linkActive'>
                        <img src={kepsek} />
                        <div>Pegawai Dinas</div>
                    </NavLink>
                    
                    <div className='menu-Row'>
                        <img src={pengawas} />
                        <div>Pengawas dan Penilik</div>
                    </div>
                    {/* <div className='menu-Row'>
                        <img src={kepsek} />
                        <div>Penilik</div>
                    </div> */}
                    <NavLink to='/InputData/Guru/biodataDiri' className='menu-Row'  onClick={clickHandle} activeClassName='linkActive'>
                        <img src={guru} />
                        <div>Kepala Sekolah dan Guru</div>
                    </NavLink>
                </div>

                <NavLink to='/tinjauData' className='menu-Row'  activeClassName='linkActive'>
                                    <img src={tinjau} />
                    <div>Tinjau Data PTK</div>
                </NavLink>
                <NavLink to='/tinjauDataPD' className='menu-Row'  activeClassName='linkActive'>
                
                    <img src={tinjau} />
                    <div>Tinjau Data Dinas</div>
                
                </NavLink>
                <NavLink exact to='/adminDashboard' className='menu-Row'  activeClassName='linkActive'>
                    <img src={profile} />
                    <div>Admin Dashboard</div>
                </NavLink>
                <div className='menu-Row' onClick={onLogout}style={{cursor:'pointer'}}>
                    <img src={logout} />
                    <div>Logout</div>
                </div>
            </div>
            <div className='navBar-Menu2'>
                <div>Support</div>
                <NavLink exact to='/profilAkun' className='menu-Row'  activeClassName='linkActive'>
                    <img src={profile} />
                    <div>Profile Akun</div>
                </NavLink>
                <NavLink exact to='/contactPerson' className='menu-Row'  activeClassName='linkActive'>
                    <img src={Dashboard} />
                    <div>Contact Person</div>
                </NavLink>
                
            </div>
            <div className='penutup'>
                <div >
                    <h1>© 2021 DISPENDIKBUD Kab. Madiun</h1>
                    <h1>Aplikasi Pendataan Tenaga </h1>
                    <h1>Pengajar Kab. Madiun</h1>
                </div>
            </div>
        </div>
    )
}

export default NavBar
