import './App.css';
import LandingPages from './pages/landingPages/LandingPages';
import { BrowserRouter as Rute, Switch, Route } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import InputData from './pages/inputData/InputData';
import NavBar from './components/navbar/navBar';
import InputGuru from './pages/inputData/inputGuru/InputGuru';
import BiodataDiri from './pages/inputData/biodataDiri/BiodataDiri';
import BiodataPD from './pages/inputData/biodataPD/BiodataPD';
import Jadwal from './pages/inputData/jadwal/jadwal';
import JadwalPD from './pages/inputData/jadwalPD/jadwalPD';
import Tinjauan from './pages/inputData/Tinjauan/Tinjauan';
import TinjauanPD from './pages/inputData/TinjauanPD/TinjauanPD';
import TerimaKasih from './pages/halamanTerimakasih/TerimaKasih';
import TerimaKasihPD from './pages/halamanTerimakasihPD/TerimaKasihPD';
import RegisterOperator from './pages/registerOperator/RegisterOperator';
import ProfilAkun from './pages/profilAkun/ProfilAkun';
import AdminDashboard from './pages/adminPages/adminDashboard';
import DetailAdmin from './pages/adminPages/detailPages/detailAdmin';
import TinjauanPages from './pages/tinjauanPages/tinjauan';
import TinjauanPagesPD from './pages/tinjauanPagesPD/tinjauanPagesPD';
import DetailTeacher from './pages/tinjauanPages/detailPages/detailTeacher';
import DetailEmployee from './pages/tinjauanPagesPD/detailPagesPD/detailEmployee';

import PrivacyPolicy from './pages/privacyPolcyPages/PrivacyPolicy';

// import Test from './pages/testing/test'



function App() {

  return (
    <div className="App">

      <Rute>
        <Switch>
          <Route path="/" exact component={LandingPages} />
          <Route path='/registerOperator' exact component={RegisterOperator}/>
          <Route path='/privacy-policy' exact component={PrivacyPolicy}/>
          
          <div >
            <NavBar />
            <div className='menu'>
              <Route path="/dashboard" exact component={Dashboard} />
              {/* <Route path="/test" exact component={Test}/> */}
              <Route path="/InputData" exact component={InputData} />
              <Route path="/InputData/Guru" exact component={InputGuru} />
              <Route path="/InputData/Guru/biodataDiri" exact component={BiodataDiri} />
              <Route path="/InputData/Dinas/biodataPD" exact component={BiodataPD} />
              <Route path="/InputData/Guru/biodataDiri/jadwal" exact component={Jadwal} />
              <Route path="/InputData/Dinas/biodataPD/jadwalPD" exact component={JadwalPD} />
              <Route path="/InputData/Guru/biodataDiri/jadwal/tinjauan" exact component={Tinjauan} />
              <Route path="/InputData/Dinas/biodataPD/jadwalPD/tinjauanPD" exact component={TinjauanPD} />
              <Route path="/InputData/Guru/biodataDiri/jadwal/tinjauan/thanks" exact component={TerimaKasih}/>
              <Route path="/InputData/Dinas/biodataPD/jadwalPD/tinjauanPD/terimaKasihPD" exact component={TerimaKasihPD}/>
              <Route path="/adminDashboard" exact component={AdminDashboard}/>
              <Route path="/adminDashboard/detailAdmin/:id" exact component={DetailAdmin}/>
              <Route path="/profilAkun" exact component={ProfilAkun}/>
              <Route path="/tinjauData" exact component={TinjauanPages}/>
              <Route path="/tinjauDataPD" exact component={TinjauanPagesPD}/>
              <Route path="/tinjauData/detailTeacher/:id" exact component={DetailTeacher}/>
              <Route path="/tinjauDataPD/detailEmployee/:id" exact component={DetailEmployee}/>
              <Route path="/adminPages" exact component={AdminDashboard}/>
              
            </div>
          </div>
        </Switch>
      </Rute>

    </div>
  );
}

export default App;
