import { React, useState, useEffect } from 'react';
import './PrivacyPolicy.css';
import landing1 from '../../assets/png/landing1.png';
import landing2 from '../../assets/png/landing2.png';
import landing3 from '../../assets/png/landing3.png';
import pemkab from '../../assets/logo/pemkab.png';
import tutwurry from '../../assets/logo/tutwurry.png';
import sadukModyar from '../../assets/logo/sadukModyar.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export default function PrivacyPolicy() {
    return (
        <div className='mainPage2'>
            <div >
                {/* <div className='boxLeft'> */}
                    {/* <div className='logoWrapper'>
                        <img src={tutwurry} alt='tutwuri' />
                        <img src={pemkab} alt='pemkab' />
                        <img src={sadukModyar} alt='saduk' />
                    </div> */}
                    <h1 className='tittle'>Siap Ngajar</h1>
                    <h2>Tentang Aplikasi dan Website Siap Ngajar</h2>
                    <p className='privacy'>Sistem Aplikasi Tenaga Pengajar (SiapNgajar) merupakan Aplikasi berbasis Data Internet yang memberikan fasilitas kepada Tenaga Pengajar dalam hal manajemen informasi aktifitas kegiatan belajar-mengajar di Kabupaten Madiun.
                        Tenaga Pengajar diberikan kemudahan dalam memberikan laporan dengan User Interface (UI) serta User Experience (UX) terkini dengan fitur-fitur yang terus dikembangkan.</p>

                    <h2>Informasi yang Kami Kumpulkan</h2>
                    <p className='privacy2'>1.Dengan mendaftar di Aplikasi dan Website ini Anda sudah memberikan “Data Pribadi” meliputi nomor ponsel, email, nama lengkap, dan data lain yang tertera saat proses pendaftaran akun di Aplikasi dan Website ini. Kami akan meminta Anda untuk mengisi Data Pribadi secara akurat dan tidak dibuat-buat.</p>
                    <p className='privacy2'>2.Kami akan mengumpulkan informasi mengenai perangkat atau media apapun yang Anda gunakan untuk mengakses Aplikasi dan Website ini meliputi IP address, sistem operasi, browser, halaman yang diakses, letak geografis, waktu akses, serta data lain yang terkait dengan perangkat yang anda gunakan.</p>
                    <p className='privacy2'>3.Kami memiliki hak untuk menggunakan Data Pribadi dan informasi lainnya yang Anda kumpulkan dengan tujuan pemasaran dan pengiklanan untuk layanan dan produk Kami.</p>
                    <p className='privacy2'>4.Dalam penggunaan Aplikasi dan Website ini, informasi yang telah Anda berikan dapat Kami berikan kepada pihak ketiga yang bekerja sama dengan Kami untuk kepentingan transaksi dan penggunaan layanan yang telah Kami sediakan.</p>
                    <p className='privacy2'>5.Seluruh data dan informasi yang Kami terima dapat Kami gunakan di kemudian hari untuk melindungi diri terhadap segala tuntutan hukum yang berlaku terkait dengan penggunaan Aplikasi dan Website dan/atau layanan yang tersedia di dalamnya.</p>
                    <p className='privacy2'>6.Anda bertanggung jawab sebagai pengguna atas kerahasiaan informasi dan Data Pribadi yang telah Anda berikan, termasuk ketika Anda mengakses link, atau layanan dari pihak ketiga yang terdapat dalam Aplikasi dan Website. Kami menyarankan agar Anda menyimpan Data Pribadi dan informasi sebaik mungkin untuk menjaga keamanan akun dan data yang anda miliki. Jika terdapat penggunaan yang tidak sah dan tanpa izin dari Kami, maka risiko penggunaan dan kerugian menjadi tanggung jawab Anda dan kami tidak bertanggung jawab atas segala hal yang timbul akibat kelalaian Anda</p>
                    <p className='privacy2'>7.Anda berhak untuk merubah data dan informasi yang telah Anda berikan kepada Kami. Segera beritahukan kepada Kami apabila terdapat penggunaan tanpa izin pada aplikasi dan Website Anda. Segala kendala yang tidak dapat diselesaikan melalui fitur pengguna dapat anda teruskan kepada admin Aplikasi dan Website Kami.</p>

                {/* </div>
                <div className='boxRight2'> */}
                    <div className='landingImage'>
                        <h2>Privasi Aplikasi dan Website Siap Ngajar</h2>
                        <p className='privacy'>Kebijakan Privasi dalam Aplikasi dan Website ini mengatur dan/atau menjelaskan seluruh layanan yang kami sediakan untuk Anda sebagai pengguna baik berupa layanan yang kami operasikan sendiri maupun layanan yang bekerja sama dengan Mitra kami. Untuk menjaga integritas Kami dan kepercayaan Anda, Kami akan menjaga kerahasiaan yang terkandung dalam data pribadi yang telah Anda berikan karena kami menganggap privasi tersebut sangat penting bagi kami. Komitmen ini Kami wujudkan dengan adanya Kebijakan Privasi yang terdapat pada laman ini. Anda dapat membaca dan memahami Kebijakan Privasi berikut untuk mempelajari bagaimana komitmen Kami untuk menjaga privasi Anda dalam Aplikasi dan Website ini. Kebijakan Privasi ini menjelaskan bagaimana kami mengumpulkan, menggunakan, dan mengakses data pribadi Anda yang telah kami dapatkan saat Anda mendaftar dan menggunakan Aplikasi dan Website ini. Dengan mengunduh, mendaftar, dan menggunakan Aplikasi dan Website ini, menjadi sebuah kesepakatan bahwa Anda telah menerima penggunaan data dan informasi yang diuraikan dalam Kebijakan Privasi ini.</p>
                        <h2>Aturan Privasi Cookie</h2>
                        <p className='privacy'>Cookie merupakan berkas berbentuk teks yang akan diunduh ke perangkat anda ketika mengunjungi suatu website. Kami menggunakan cookie untuk tujuan mencari data anda ketika itu dibutuhkan, menggunakan data cookie untuk pengembangan Aplikasi dan Website, dan meningkatkan pengalaman anda saat mengakses laman pada Aplikasi dan Website dan/atau website kami. Anda dapat menyetujui atau tidak menyetujui penggunaan cookie dengan mengaturnya melalui perangkat dan/atau browser anda.</p>
                        <h2>Perubahan Aturan Privasi</h2>
                        <p className='privacy'>Kami memiliki hak penuh untuk melakukan perubahan, pembaruan, penambahan maupun pengurangan seluruh atau sebagian ketentuan dari Kebijakan Privasi ini tanpa pemberitahuan terlebih dahulu. Segala perubahan akan Kami informasikan kepada Anda melalui masing-masing akun Anda. Kami sangat menyarankan agar Anda memeriksa secara berkala mengenai Kebijakan Privasi ini. Dengan menggunakan layanan Aplikasi dan Website, maka Kami menganggap Anda memahami dan menyetujui kebijakan yang tertera pada laman ini.</p>
                    </div>
                {/* </div> */}
            </div>
        </div>
    )
}
