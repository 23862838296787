import {React,useEffect} from 'react'
import './Dashboard.css'
import fake from '../../assets/dashboard/Fake.svg'
import { useHistory } from "react-router-dom"



function Dashboard() {
    const navigate = useHistory()
    const dataLogin = JSON.parse(localStorage.getItem("loginData"))

    
    // useEffect(() => {
    //     if (dataLogin.school.school_id === undefined) {
    //         localStorage.clear()
    //         navigate.push('/')
    //     }
    // },[])
    return (
        <div className='dashboardWrapper'>
            <img src={fake}/>
        </div>
    )
}

export default Dashboard
