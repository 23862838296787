import { React, useEffect, useState } from 'react'
import axios from 'axios';
import Table from 'reactstrap'
import './Tinjauan.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import stark from '../../../assets/tinjauan/stark.svg'
import { useHistory } from "react-router-dom";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function TinjauanPD() {
    const navigate = useHistory()
    const login = JSON.parse(localStorage.getItem("loginData"))
    const Data = JSON.parse(localStorage.getItem("Jadwal"))
    const biodata = JSON.parse(localStorage.getItem("biodata"))
    const sekolah = JSON.parse(localStorage.getItem("sekolah"))

    const [status, setStatus] = useState({
        isNotError : true,
        errorMassage : "",
        isSubmiting : false,
        loading : "none",
        buttonEdit:true,
        buttonInput:false
    })

    useEffect(() => {
       if (biodata.isEdit != undefined){
           setStatus({
               ...status,
               buttonEdit:false,
               buttonInput:true
           })
       }
    }, [])
    const [dataJadwal, setDataJadwal] = useState([
        Data
    ])

    const [jadwalPost, setJadwalPost] = useState([])
    useEffect(() => {
        console.log(dataJadwal[0].length)
        for (let i = 0; i < dataJadwal[0].length; i++) {
            setJadwalPost(oldPost => [
                ...oldPost,
                {
                    day: dataJadwal[0][i].day,
                    subject: dataJadwal[0][i].subject,
                    class: "XA",
                    start: dataJadwal[0][i].start,
                    end: dataJadwal[0][i].end_activity,
                    location: dataJadwal[0][i].location,
                }
            ])
        }
    }, [])

    const handleback = (e) => {
        e.preventDefault()
        navigate.push('/InputData/Dinas/biodataPD/jadwalPD')
    }
    const handleUpdateSubmit = (e) => {
        e.preventDefault()
        setStatus({
            ...status,
            loading:"",
            isSubmiting:true
        })
        console.log(jadwalPost)
        var data = {
            "school_id": login.school.school_id,
            "address": biodata.kecamatanDomisili,
            "full_name": biodata.namaLengkap,
            "nik": biodata.nik,
            "year": biodata.tahun,
            "month": biodata.bulan,
            "sex": biodata.gender,
            "marriage_status": biodata.statusPerkawinan,
            "birthday": biodata.tanggalLahir,
            "birthday_place": biodata.tempatLahir,
            "employee_status": biodata.statusKepegawaian,
            "employee_start_date": biodata.tmtAwal,
            "employee_end_date": biodata.tmtAkhir,
            "dapodik_registered": biodata.dapodik,
            "phone": biodata.nomorTelepon,
            "job_position": biodata.jabatan,
            "job_position_group": biodata.kelompokJabatan,
            "job_position_level": biodata.jenjangJabatan,
            "email": biodata.email,
            "image_url": "",
            "location": biodata.bujur + ", " + biodata.lintang,
            "nip": biodata.nip,
            "teacher_schedule": jadwalPost
        }
        var userToken = "Bearer " + login.token
        var config = {
            method: 'put',
            url: 'https://siapngajar-be-y2fugkloba-et.a.run.app/api/v1/teachers/'+biodata.teacher_id,
            headers: {
                'Authorization': userToken,
                'Content-Type': 'text/plain'
            },
            data: data
        };
        console.log(data)
        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                navigate.push('/InputData/Dinas/biodataPD/jadwalPD/tinjauanPD/TerimaKasihPD')
            })
            .catch(function (error) {
                console.log(error.response.data.meta.message);
                setStatus({
                    ...status,
                    isNotError : false,
                    errorMassage : error.response.data.meta.message
                })
            });
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setStatus({
            ...status,
            loading:"",
            isSubmiting:true
        })
        console.log(jadwalPost)
        var data = JSON.stringify({
            "school_id": login.school.school_id,
            "address": biodata.kecamatanDomisili,
            "full_name": biodata.namaLengkap,
            "nik": biodata.nik,
            "nip": biodata.nip,
            "year": biodata.tahun,
            "month": biodata.bulan,
            "sex": biodata.gender,
            "marriage_status": biodata.statusPerkawinan,
            "birthday": biodata.tanggalLahir,
            "birthday_place": biodata.tempatLahir,
            "employee_status": biodata.statusKepegawaian,
            "employee_start_date": biodata.tmtAwal,
            "employee_end_date": biodata.tmtAkhir,
            "dapodik_registered": biodata.dapodik,
            "phone": biodata.nomorTelepon,
            "job_position": biodata.jabatan,
            "job_position_group": biodata.kelompokJabatan,
            "job_position_level": biodata.jenjangJabatan,
            "email": biodata.email,
            "image_url": "",
            "location": biodata.lintang + ", " + biodata.bujur,
            "employee_schedule": jadwalPost
        })
        var userToken = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxMzY5fQ.uqjdOVwFsD3iG8u_yaaW1W5WzVT6nzD0lOqEtbzTUuQ'
        var config = {
            method: 'post',
            url: 'https://siapngajar-be-y2fugkloba-et.a.run.app/api/v1/employees',
            headers: {
                'Authorization': userToken,
                'Content-Type': 'application/json'
            },
            data: data
        };
        console.log(data)
        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                navigate.push('/InputData/Dinas/biodataPD/jadwalPD/tinjauanPD/terimaKasihPD')
            })
            .catch(function (error) {
                console.log(error.response.data.meta.message);
                setStatus({
                    ...status,
                    isNotError : false,
                    errorMassage : error.response.data.meta.message
                })
            });
    }
    const handleEditBio = (e) => {
        e.preventDefault()
        navigate.push('/InputData/Dinas/biodataPD')
    }

    return (
        <div className='tinjauan-Wrapper'>
            <div className='errorTinjauan' hidden = {status.isNotError}>
                {status.errorMassage}
            </div>
            <h1>Tinjauan</h1>
            <div className='tinjauanProfile-Wrapper'>
                {/* <div className='tinjauanImage-Wrapper'>
                    <img src={stark} />
                </div> */}
                <div className='tinjauan-form-Wrapper'>
                    <div className='doubleTinjauan'>
                        <div>
                            <div>NIK</div>
                            <input value={biodata.nik} readOnly />
                        </div>
                        <div>
                            <div>NIP</div>
                            <input value={biodata.nip} readOnly />
                        </div>
                    </div>
                    <div className='doubleTinjauan'>
                        <div>
                            <div>Staus Kepegawaian</div>
                            <input value={biodata.statusKepegawaian} readOnly />
                        </div> 
                        <div>
                            <div>TMT PNS</div>
                            <input value={biodata.tmtAwal} readOnly />
                        </div>
                        <div>
                            <div>TMT Pangkat Terakhir</div>
                            <input value={biodata.tmtAkhir} readOnly />
                        </div>
                    </div>
                    <div className='doubleTinjauan'>
                        <div>
                            <div>Kelompok Jabatan</div>
                            <input value={biodata.kelompokJabatan} readOnly />
                        </div>
                        <div>
                            <div>Jabatan</div>
                            <input value={biodata.jabatan} readOnly />
                        </div>
                    </div>
                    <div className='singleTinjauan'>
                        <div>Nama</div>
                        <input value={biodata.namaLengkap} readOnly />
                    </div>
                    <div className='doubleTinjauan'>
                        <div>
                            <div>Gender</div>
                            <input value={biodata.gender} readOnly />
                        </div>
                        <div>
                            <div>Status Perkawinan</div>
                            <input value={biodata.statusPerkawinan} readOnly />
                        </div>
                    </div>
                    <div className='doubleTinjauan'>
                        <div>
                            <div>Tempat Lahir</div>
                            <input value={biodata.tempatLahir} readOnly />
                        </div>
                        <div>
                            <div>Tanggal Lahir</div>
                            <input value={biodata.tanggalLahir} readOnly />
                        </div>
                    </div>
                    <div className='doubleTinjauan'>
                        <div>
                            <div>Nomor Telefon</div>
                            <input value={biodata.nomorTelepon} />
                        </div>
                        <div>
                            <div>Email</div>
                            <input value={biodata.email} />
                        </div>
                    </div>
                    <div className='ubahTinjauan' >
                        <button><FontAwesomeIcon icon={faPencilAlt} onClick={handleEditBio} /> Ubah</button>
                        <div>Sudah Betul</div>
                        <input type="radio" />
                    </div>
                </div>
            </div>
            <div className='judulJadwal'>
                <div className='judulJadwal1'>Jadwal</div>
                <div className='judulJadwal2'>Mengajar Semester</div>
            </div>
            <div className='tableContainer'>
                <table>
                    <th>
                        Hari
                    </th>
                    <th>
                        Waktu
                    </th>
                    <th>
                        Mapel
                    </th>
                    <th>
                        lokasi
                    </th>
                    {dataJadwal[0].map(dataJadwal =>
                        <tr>
                            <td>{dataJadwal.day}</td>
                            <td>{dataJadwal.start}-{dataJadwal.end_activity}</td>
                            <td>{dataJadwal.subject}</td>
                            <td>{dataJadwal.location}</td>
                        </tr>

                    )}
                </table>
            </div>

            <div className='TinjauanButton-Wrapper'>
                <button onClick={handleSubmit} disabled={status.isSubmiting} style={{marginLeft:"20px"}} hidden={status.buttonInput}> <FontAwesomeIcon icon={faSpinner} style={{display:status.loading}} spin/>Simpan dan Lanjut</button>
                <button onClick={handleUpdateSubmit} disabled={status.isSubmiting} style={{backgroundColor:"turquoise"}} hidden={status.buttonEdit}> <FontAwesomeIcon icon={faSpinner} style={{display:status.loading}} spin/>Kirim Perubahan</button>
                <h1 onClick={handleback}>←Kembali</h1>
            </div>
        </div>
    )
}

export default TinjauanPD
