import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { computeHeadingLevel, logRoles } from '@testing-library/react';
import { hasSelectionSupport, isValidInputTimeValue } from '@testing-library/user-event/dist/utils';
import axios from 'axios';
import Moment from 'react-moment';
import Swal from 'sweetalert2';
import {React,useState, useEffect }from 'react'
import { useResolvedPath } from 'react-router';
import { useParams,useHistory } from 'react-router-dom'
import { Row, Table } from 'reactstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import './detailAdm.css'


function DetailAdmin() {
    const {id} = useParams();
    const navigate = useHistory()
    const [dataJadwal, setDataJadwal] = useState([])
    const [payloadBiodata, setPayloadBiodata] = useState({})
 
    const login = JSON.parse(localStorage.getItem("loginData"))
    const [teachers, setTeachers] = useState([{}, {}
    ])
    const uri = 'https://siapngajar-be-y2fugkloba-et.a.run.app/api/v1/attandance_detail/' + id
    const onClick = e => {
        e.preventDefault()
    }
    
    var userToken = "Bearer " + login.token
    var config = {
        method: 'get',
        url: uri,
        headers: {
            'Authorization': userToken
        }
    };

    useEffect(() => {
        axios(config)
            .then(function (response) {
                console.log(config)
                console.log(JSON.stringify(response.data.data));
                // setTeachers(
                //     response.data.data
                // )
                setDataJadwal(
                    response.data.data
                )
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    const deleteHandler = (e) => {
        e.preventDefault()
        var config = {
            method: 'delete',
            url: 'https://siapngajar-be-y2fugkloba-et.a.run.app/api/v1/teachers/'+e.target.name,
            headers: { 
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo1MzV9.BGv3zEcuLqdnBrp0Bzlznn3AR2WFh0hmPmuY7oeNVns'
            }
          };
          
          axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            window.location.reload();
          })
          .catch(function (error) {
            console.log(error);
          });
        console.log(e.target.name)
    }

    return (
        <div className="tinjauData-Wrapper">
            <div className='tinjauData-Header'>
                <div className="judulTinjauan">Data Pegawai</div>
                <div className="namaSekolah">{login.school.school_name}</div>
            </div>
            {/* <div className='Biodata-wrapper button'>
                <button onClick={onClick} style={{ cursor: "pointer" }}>Unduh</button>
            </div> */}
            <div className='Biodata-wrapper button'>
            <ReactHTMLTableToExcel
                    id="DetailTable2"
                    className="Biodata-wrapper2 button"
                    table="DetailTable"
                    filename="Rekap Absensi"
                    sheet="Sheet 1"
                    buttonText="Unduh"
                />
            </div> 
            <div className='Biodata-wrapper button'>
                <Table className='Biodata-wrapper button' id='DetailTable'>
                    <thead>
                        <th>
                            Tanggal
                        </th>
                        <th>
                            Jam Update
                        </th>

                        <th>
                            Lokasi
                        </th>
                        <th>
                            Status
                        </th>
                        <th style= {{display: "none"}}>
                            Report
                        </th>
                    </thead>
                    <tbody>
                        {dataJadwal.map(item =>
                        <tr>
                            <td>
                                <Moment format="DD-MM-YYYY"  >{item.start}</Moment>
                            </td>
                            <td>
                                <Moment format="hh:mm"  >{item.start}</Moment>
                            </td>
                        
                            <td>
                                {item.location}
                            </td>
                            <td>
                            {(() => {
                                if (item.absence == '0') {
                                return (
                                    <div className='Biodata-wrapper3 button'>
                                    <button onClick={() => {
                                        Swal.fire({
                                            imageUrl: item.image_url,
                                            imageWidth: 300,
                                            imageHeight: 300,
                                            text: item.report,
                                })}} style={{ cursor: "pointer" }}>Tidak Hadir</button>
                                </div>
                                )
                                } else if (item.absence == '1') {
                                return (
                                    <div className='Biodata-wrapper2 button'>
                                    <button onClick={() => {
                                        Swal.fire({
                                            imageUrl: item.image_url,
                                            imageWidth: 300,
                                            imageHeight: 300,
                                            text: item.report,
                                })}} style={{ cursor: "pointer" }}>Hadir</button>
                                </div>
                                )
                                }
                            })()}
                            </td>
                            <td style= {{display: "none"}}>
                                {item.report}
                            </td>
                        </tr>    
                    )}
                </tbody>
                    
               </Table>
            </div>

            {/* <button onClick ={deleteHandler}>Test</button> */}
        </div>
    )
}

export default DetailAdmin

