import { React, useState, useEffect } from 'react'
import axios from 'axios';
import Moment from 'react-moment';
import './adminDash.css';
import { Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NavLink as Link, NavLink } from "react-router-dom";
import { Routes } from 'react-router';
import { isCompositeComponentWithType } from 'react-dom/test-utils';
// import XLSX from "xlsx";



function AdminDashboard() {

    const login = JSON.parse(localStorage.getItem("loginData"))
    const [teachers, setTeachers] = useState([{}, {}
    ])
    const onClick = e => {
        e.preventDefault()
    }
    const uri = 'https://siapngajar-be-y2fugkloba-et.a.run.app/api/v1/teachers?school_id=' + login.school.school_id

    var userToken = "Bearer " + login.token
    var config = {
        method: 'get',
        url: uri,
        headers: {
            'Authorization': userToken
        }
    };

    useEffect(() => {
        axios(config)
            .then(function (response) {
                console.log(config)
                console.log(JSON.stringify(response.data.data));
                setTeachers(
                    response.data.data
                )
            })
            .catch(function (error) {
                console.log(error);
            });
    
    }, [])

        const deleteHandler = (e) => {
        e.preventDefault()
        var config = {
            method: 'delete',
            url: 'https://siapngajar-be-y2fugkloba-et.a.run.app/api/v1/teachers/'+e.target.name,
            headers: { 
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo1MzV9.BGv3zEcuLqdnBrp0Bzlznn3AR2WFh0hmPmuY7oeNVns'
            }
          };
          
          axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            window.location.reload();
          })
          .catch(function (error) {
            console.log(error);
            
          })
        console.log(e.target.name)
    }

    return (
        <div className="tinjauData-Wrapper">
            <div className='tinjauData-Header'>
                <div className="judulTinjauan">Database Admin</div>
                <div className="namaSekolah">{login.school.school_name}</div>
            </div>
            <div className='tableContainer'>
                <Table>
                    <th style= {{display: "none"}}>
                        No.
                    </th>
                    <th>
                        Nama
                    </th>
                    <th>
                        Status Kepegawaian
                    </th>
                    <th>
                        Jabatan
                    </th>
                    <th>
                        Keterangan                    
                    </th>
                    {teachers.map(item =>
                        <tr>
                            <td style= {{display: "none"}}>{Number}</td>
                            <td>{item.full_name}</td>
                            <td>{item.employee_status}</td>
                            <td>{item.job_position}</td>
                            <td className="tinjuanButtonAlign">
                                <Link to={
                                    {
                                        pathname: `adminDashboard/detailAdmin/${item.teacher_id}`
                            
                                    }
                                } className="tinjuanButtonAlign">
                                    <button className="lihat"><FontAwesomeIcon icon={faEye} /> Lihat</button>
                                </Link>
                                
                            </td>
                        </tr>
                    )}
                </Table>
                <div> </div>
            </div>
          

            {/* <button onClick ={deleteHandler}>Test</button> */}
        </div>
    )
}

export default AdminDashboard