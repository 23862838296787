import {React, useEffect, useState} from 'react'
import axios from 'axios'
import './inputData.css'
import guru from '../../assets/InputData/Guru.png'
import KepalaSekolah from '../../assets/InputData/KepalaSekolah.png'
import Pengawas from '../../assets/InputData/Pengawas.png'
import Penilik from '../../assets/InputData/Penilik.png'
import { useHistory } from "react-router-dom";
import Jadwal from './jadwal/jadwal';


function InputData() {
    
    const navigate = useHistory()
   
    useEffect(() => {
        var config = {
            method: 'get',
            url: 'https://siapngajar-be-y2fugkloba-et.a.run.app/api/v1/schools',
            headers: { 
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0fQ.-gwXaqC2WsGoPnGfvVVm28fhKWWYKSm0LS_AgoF9cgQ'
            },
          };
          
          axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });
    }, [])

    const clickHandle = event =>{
        event.preventDefault()
        navigate.push('./InputData/Guru/biodataDiri')
        localStorage.removeItem('biodata')
        localStorage.removeItem('Jadwal')
    }

    const clickHandlePD = event =>{
        event.preventDefault()
        navigate.push('./InputData/Dinas/biodataPd')
        localStorage.removeItem('biodata')
        localStorage.removeItem('Jadwal')
    }

    return (
        <div className='InputData'>
            <h1>Input Data</h1>
            <div className='cardWrapper'>
            <div className='card'>
                    <div className='iconWrapper' onClick={clickHandlePD} style={{ cursor: "pointer" }}>
                        <div>
                            <img src={KepalaSekolah} alt="" />
                        </div>
                    </div>
                    <div className='textWrapper'>
                        <h1>Pegawai Dinas</h1>
                    </div>
                </div>
                <div className='card' >
                    <div className='iconWrapper'>
                        <div>
                            <img src={Pengawas} alt="" />
                        </div>
                    </div>
                    <div className='textWrapper'>
                        <h1>Pengawas</h1>
                    </div>
                </div>
                {/* <div className='card'>
                    <div className='iconWrapper'>
                        <div>
                            <img src={Penilik} alt="" />
                        </div>
                    </div>
                    <div className='textWrapper'>
                        <h1>Penilik</h1>
                    </div>
                </div> */}
                <div className='card' onClick={clickHandle} style={{cursor:"pointer"}}>
                    <div className='iconWrapper'>
                        <div>
                            <img src={guru} alt="" />
                        </div>
                    </div>
                    <div className='textWrapper'>
                        <h1>Kepala Sekolah dan Guru</h1>
                    </div>
                </div>
            </div>
            <h2>* Silakan masukan data sesuai dengan Jabatan</h2>
        </div>
    )
}

export default InputData
