import axios from 'axios';
import {React,useState, useEffect }from 'react'
import { useParams,useHistory } from 'react-router-dom'
import { Table } from 'reactstrap';
import './detailTeacher.css'



function DetailTeacher() {
    const {id} = useParams();
    const navigate = useHistory()
    const login = JSON.parse(localStorage.getItem("loginData"))
    const [teacherDetails, setTeacherDetails] = useState({})
    const [dataJadwal, setDataJadwal] = useState([])
    const [payloadBiodata, setPayloadBiodata] = useState({})


    useEffect(() => {
        var userToken = "Bearer " + login.token
        var config = {
            method: 'get',
            url: 'https://siapngajar-be-y2fugkloba-et.a.run.app/api/v1/teachers/'+id,
            headers: { 
              'Authorization': userToken
            }
          };
          
          axios(config)
          .then(function (response) {
            setTeacherDetails(response.data.data)
            console.log(response.data.data);
            setPayloadBiodata({
                teacher_id: id,
                tmtAwal: response.data.data.employee_start_date,
                tempatLahir: response.data.data.birthday_place,
                jabatan :response.data.data.job_position,
                namaLengkap : response.data.data.full_name,
                dapodik: response.data.data.dapodik_registered,
                email: response.data.data.email,
                nik: response.data.data.nik,
                nip: response.data.data.nip,
                nomorTelepon: response.data.data.phone,
                nrg: response.data.data.nrg,
                nuptk: response.data.data.nuptk,
                nuks: response.data.data.nuks,
                kecamatanDomisili: response.data.data.address,
                statusPerkawinan: response.data.data.marriage_status,
                tanggalLahir: response.data.data.birthday,
                location : response.data.data.location,
                statusKepegawaian: response.data.data.employee_status,
                gender : response.data.data.sex,
                lintang : response.data.data.location.substring(0,response.data.data.location.indexOf(',')),
                bujur : response.data.data.location.substring(response.data.data.location.indexOf(',')+1),
                isEdit : true,
            })
            setDataJadwal(response.data.data.schedules)
          })
          .catch(function (error) {
            console.log(error);
          });
    }, [])

    const handleEditButton = (e) =>{
        e.preventDefault();
        console.log(payloadBiodata)
        localStorage.setItem("biodata",JSON.stringify(payloadBiodata))
        localStorage.setItem("Jadwal",JSON.stringify(dataJadwal))
        console.log(dataJadwal)
        navigate.push('/InputData/Guru/biodataDiri')
    }

    return (
        <div className = "detailTeacher-Wrapper">
            <h1>
                Detail Data Tenaga Pengajar
            </h1>
            <Table>
                <tr>
                    <td>Nama</td>
                    <td>{teacherDetails.full_name}</td>
                </tr>
                <tr>
                    <td>NIK</td>
                    <td>{teacherDetails.nik}</td>
                </tr>
                <tr>
                    <td>NIP</td>
                    <td>{teacherDetails.nip}</td>
                </tr>
                <tr>
                    <td>NRG</td>
                    <td>{teacherDetails.nrg}</td>
                </tr>
                <tr>
                    <td>NUKS</td>
                    <td>{teacherDetails.nuks}</td>
                </tr>
                <tr>
                    <td>NUPTK</td>
                    <td>{teacherDetails.nuptk}</td>
                </tr>
                <tr>
                    <td>Alamat</td>
                    <td>{teacherDetails.address}</td>
                </tr>
                <tr>
                    <td>Tempat, Tanggal Lahir</td>
                    <td>{teacherDetails.birthday_place}, {teacherDetails.birthday}</td>
                </tr>
                <tr>
                    <td>Terdaftar di DAPODIK</td>
                    <td>{teacherDetails.dapodik_registered}</td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>{teacherDetails.email}</td>
                </tr>
                <tr>
                    <td>Tanggal Pengangkatan</td>
                    <td>{teacherDetails.employee_start_date}</td>
                </tr>
                <tr>
                    <td>Status Kepegawaian</td>
                    <td>{teacherDetails.employee_status}</td>
                </tr>
                <tr>
                    <td>Jabatan</td>
                    <td>{teacherDetails.job_position}</td>
                </tr>
                <tr>
                    <td>Koordinat</td>
                    <td>{teacherDetails.location}</td>
                </tr>
                <tr>
                    <td>Status Perkawinan</td>
                    <td>{teacherDetails.marriage_status}</td>
                </tr>
                <tr>
                    <td>Phone</td>
                    <td>{teacherDetails.phone}</td>
                </tr>
                <tr>
                    <td>Gender</td>
                    <td>{teacherDetails.sex}</td>
                </tr>
            </Table>
            <h1>
                Jadwal
            </h1>
            <table className = "tabelJadwal">
                <th>
                    Hari
                </th>
                <th>
                    Waktu
                </th>
                <th>
                    Mata Pelajaran
                </th>
                <th>
                    Lokasi
                </th>
                <th>
                    Status
                </th>
                {dataJadwal.map(item =>
                    <tr>
                        <td>
                            {item.day}
                        </td>
                        <td>
                            {item.start}-{item.end_activity}
                        </td>
                        <td>
                            {item.subject}
                        </td>
                        <td>
                            {item.location}
                        </td>
                        <td>
                            {item.status}
                        </td>
                    </tr>    
                )}
            </table>
            <button onClick = {handleEditButton}>Edit</button>
        </div>

    )
}

export default DetailTeacher
