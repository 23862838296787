import { React, useState, useEffect } from 'react';
import { useHistory, } from "react-router-dom";
import axios from 'axios';
import './LandingPages.css';
import landing2 from '../../assets/png/landing2.png';
import pemkab from '../../assets/logo/pemkab.png';
import tutwurry from '../../assets/logo/tutwurry.png';
import sadukModyar from '../../assets/logo/sadukModyar.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const api = "https://siapngajar-be-y2fugkloba-et.a.run.app/api/v1/"
const qs = require('querystring')

function LandingPages() {
    const navigate = useHistory()
    const loginData = JSON.parse(localStorage.getItem("loginData"))

    useEffect(() => {
        if (loginData != null) {
            navigate.push('./dashboard')
        }
    }, [])

    const [data, setData] = useState({
        userName: "",
        password: "",

    })

    const [status, setStatus] = useState({
        userNameError: "",
        passwordError: "",
        correction: "none",
        isActive: false,
        isLoading: "none"
    })

    const formChange = event => {
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
        setStatus({
            ...status,
            correction: "none"
        })
    }

    useEffect(() => {
        if (data.userName != "") {
            setStatus({
                ...status,
                userNameError: ""
            })
        }
        if (data.password != "") {
            setStatus({
                ...status,
                passwordError: ""
            })
        }
    }, [data])

    const clickHandle = event => {
        event.preventDefault()
        setStatus({
            ...status,
            isActive: true,
            isLoading: ""
        })
        if (data.userName == "" && data.password == "") {
            setStatus({
                ...status,
                userNameError: "username belum terisi",
                passwordError: "password belum terisi"
            })
        }
        else if (data.userName == "") {
            setStatus({
                ...status,
                userNameError: "username belum terisi",

            })
        }
        else if (data.password == "") {
            setStatus({
                ...status,
                passwordError: "password belum terisi"
            })
        } else {
            var payload = qs.stringify({
                "username": data.userName,
                "password": data.password
            });

            var config = {
                method: 'post',
                url: 'https://siapngajar-be-y2fugkloba-et.a.run.app/api/v1/sessions',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: payload
            };

            axios(config)
                .then(function (response) {
                    if (response.data.meta.code === 200) {
                        localStorage.setItem("loginData", JSON.stringify(response.data.data))
                        navigate.push('./dashboard')
                        console.log(JSON.stringify(response.data.data));
                    }
                })
                .catch(function (error) {
                    if (error.response.data.meta.code === 422) {
                        setStatus({
                            ...status,
                            correction: ""
                        })
                    }
                });
            // localStorage.setItem("loginData", JSON.stringify(data))
            // navigate.push('./dashboard')
        }

    }





    return (
        <div>
            <div className='ErrorAlert' style={{ display: status.correction }}>Username Password anda salah</div>
            <div className='mainPage'>
                <div className='boxLeft'>
                    <div className='logoWrapper'>
                        <img src={tutwurry} alt='tutwuri' />
                        <img src={pemkab} alt='pemkab' />
                        <img src={sadukModyar} alt='saduk' />
                    </div>
                    <h1>Selamat Datang</h1>
                    <h2>Formulir Pendataan Tenaga Pengajar</h2>
                    <p>Dinas Pendidikan dan Kebudayaan</p>
                    <p>Kab. Madiun</p>
                    <div className='inputWrapper'>
                        <form onChange={formChange}>
                            <input placeholder='User Name' name='userName' value={data.userName} />
                            <div className='login-alert'>{status.userNameError}</div>
                            <input placeholder='Password' type="password" name='password' value={data.password} />
                            <div className='login-alert'>{status.passwordError}</div>
                        </form>
                    </div>
                    <div className='forget'>Lupa Password ?</div>
                    <button onClick={clickHandle} disabled={data.isActive}> <FontAwesomeIcon icon={faSpinner} style={{ display: status.isLoading }} spin /> Masuk </button>
                </div>
                <div className='boxRight'>
                    <div className='landingImage'>
                       
                        <img src={landing2} alt='gambar' />
                        
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LandingPages;


