import { React, useState, useEffect } from 'react'
import './RegisterOperator.css'
import axios from 'axios'
import Modal from "react-modal";
import { useHistory, } from "react-router-dom";

function RegisterOperator() {
    const navigate = useHistory()
    const token = "dispendikbud2022"
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            width: '90%',
            height: '75%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            color: "color: #00828F;"
        }
    };
    const [school, setSchool] = useState([
        {
            "id": 276,
            "name": "SDN 01 Garon",
            "address": "Kabupaten Madiun",
            "school_location": "0,0",
            "school_type": "SD",
            "employee_count": 1
        },
    ])

    const [payLoad, setPayLoad] = useState({
        userName: "",
        password: "",
        passwordRepeat: "",
        email:"",
    })
    const [password, setPassword] = useState("")
    const [status, setStatus] = useState({
        schoolError: "",
        userNameError: "",
        passwordError: "",
        passwordRepeatError: "",
        modaError: "",
        emaiError:"",
        alertMassage:"",
        alertColor:"green",
        alertDisplay:"none",
        modalIsOpen: true,
    })

    useEffect(() => {
        if (payLoad.school != undefined) {
            setStatus({
                ...status,
                schoolError: ""
            })
        }
        if (payLoad.userName != "") {
            setStatus({
                ...status,
                userNameError: ""
            })
        }
        if (payLoad.password != "") {
            setStatus({
                ...status,
                passwordError: ""
            })
        }
        if (payLoad.passwordRepeat != "") {
            setStatus({
                ...status,
                passwordRepeatError: ""
            })
        }
    }, [payLoad])


    useEffect(() => {
        var qs = require('qs');
        var data = qs.stringify({

        });
        var config = {
            method: 'get',
            url: 'https://siapngajar-be-y2fugkloba-et.a.run.app/api/v1/schools',
            headers: {
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0fQ.-gwXaqC2WsGoPnGfvVVm28fhKWWYKSm0LS_AgoF9cgQ'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setSchool(
                    response.data.data
                )
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    const handleFormChange = e => {
        setPayLoad({
            ...payLoad,
            [e.target.name]: e.target.value
        })
    }
    const handleButtonClick = e => {
        e.preventDefault()
        if (payLoad.userName == "" && payLoad.password == "" && payLoad.school == undefined) {
            setStatus({
                ...status,
                userNameError: "Username belum terisi",
                passwordError: "Password belum terisi",
                schoolError: "Sekolah Belum Terisi",
                emaiError: "email Belum Terisi",
            })
        }
        else if (payLoad.userName == "" && payLoad.password == "") {
            setStatus({
                ...status,
                userNameError: "Username belum terisi",
                passwordError: "Password belum terisi",
                schoolError: "Sekolah Belum Terisi"
            })
        }
        else if (payLoad.school == undefined) {
            setStatus({
                ...status,
                schoolError: "Sekolah Belum Terisi"
            })
        }
        else if (payLoad.userName == "") {
            setStatus({
                ...status,
                userNameError: "Username belum terisi"
            })
        }
        else if (payLoad.password == "") {
            setStatus({
                ...status,
                passwordError: "Password belum terisi"
            })
        }
        else if (payLoad.password != payLoad.passwordRepeat) {
            setStatus({
                ...status,
                passwordRepeatError: "Mohon Ulangi Password"
            })
        } else if (payLoad.email == ""){
            setStatus({
                ...status,
                emaiError: "email Belum Terisi"
            })
        } else {
            var axios = require('axios');
            var data = JSON.stringify({
                "username": payLoad.userName,
                "email": payLoad.email,
                "role": 2,
                "school_id": parseInt(payLoad.school),
                "school_name": "",
                "school_type": "",
                "password": payLoad.password,
                "confirm_password": payLoad.passwordRepeat
            });

            var config = {
                method: 'post',
                url: 'https://siapngajar-be-y2fugkloba-et.a.run.app/api/v1/users',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            console.log(data)
            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data.meta.message));
                    setStatus({
                        ...status,
                        alertMassage: response.data.meta.message,
                        alertColor:"green",
                        alertDisplay:""
                    })
                    navigate.push('./')
                })
                .catch(function (error) {
                    console.log(error.response);
                    setStatus({
                        ...status,
                        alertMassage: error.response.data.meta.message,
                        alertColor:"red",
                        alertDisplay:""
                    })
                });

        }
    }

    const handleModalChange = e => {
        setPassword(e.target.value)
        setStatus({
            ...status,
            modaError: ""
        })
    }

    const handleModalButton = e => {
        if (password == token) {
            setStatus({
                ...status,
                modalIsOpen: false
            })
        } else {
            setStatus({
                ...status,
                modaError: "Password Salah"
            })
        }
    }

    return (
        <div className='registerOperatorWrapper'>
            <div className='registerAlert' style={{display:status.alertDisplay,backgroundColor:status.alertColor,color:"white"}}>{status.alertMassage}</div>
            <Modal isOpen={status.modalIsOpen} style={customStyles}>
                <div className='modalAlign'>
                    <div className="modalJudul">
                        Silahkan Masukan Password Untuk Melanjutkan
                    </div>
                    <input className='modalInput' value={password} onChange={handleModalChange} />
                    <div className='registerAlert'>{status.modaError}</div>
                </div>
                <div className='buttonAlign'>
                    <button onClick={handleModalButton} className='modalButton'>Kirim</button>
                </div>
            </Modal>
            <div className="registerJudul">
                Registrasi Akun Operator Sekolah
            </div>
            <div className='subJudul'>
                <div className='registerSubJudul'>
                    Silahkan masukkan data untuk akun baru Anda
                </div>
                <div className='registerSubJudul'>
                    pada kolom yang tersedia
                </div>
            </div>
            {/* box form */}
            <form onChange={handleFormChange}>
                <div className='boxForm'>
                    <label>Nama Sekolah</label>
                    <select name="school">
                        <option>Silahkan Pilih</option>
                        {school.map(item =>
                            <option value={item.id}>{item.name}</option>
                        )}
                    </select>
                    <div className='registerAlert'>{status.schoolError}</div>
                    <label>Username</label>
                    <input name="userName" value={payLoad.userName} />
                    <div className='registerAlert'>{status.userNameError}</div>
                    <label>Email</label>
                    <input name="email"  value={payLoad.email} />
                    <div className='registerAlert'>{status.emaiError}</div>
                    <label>Password</label>
                    <input name="password" type="password" value={payLoad.password} />
                    <div className='registerAlert'>{status.passwordError}</div>
                    <label>Ulangi Password</label>
                    <input name="passwordRepeat" type="password" value={payLoad.passwordRepeat} />
                    <div className='registerAlert'>{status.passwordRepeatError}</div>
                    <button onClick={handleButtonClick}>Kirim</button>
                </div>
            </form>
        </div>
    )
}

export default RegisterOperator
