import {React,useEffect, useState}from 'react'
import './ProfilAkun.css'
import Modal from "react-modal";
import axios from 'axios'


function ProfilAkun() {
    
    const login = JSON.parse(localStorage.getItem("loginData"))

    const [condition, setCondition] = useState({
        modalIsOpen : false,
        modalMessage : "",
        massageBackground:""
    });

    const [passData, setPassData] = useState({
        current_password: "",
        new_password: "",
        confirm_new_password: ""
    });
    

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            width: '90%',
            height: '75%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            color: "color: #00828F;"
        }
    };

    useEffect(() => {
        console.log(login.token)
    },[])

    const handleEditButton = (e) =>{
        e.preventDefault();
        setCondition({
            ...condition,
            modalIsOpen : true,
        })
    }

    const handleTest = (e) =>{
        e.preventDefault();
        console.log(passData)
    }

    const onChange = (e) =>{
        e.preventDefault();
        setPassData({
            ...passData,
            [e.target.name]: e.target.value
        })
    }

    const handleUpdateSent = (e) =>{
        e.preventDefault();
        var data = JSON.stringify({
            "current_password": passData.current_password,
            "new_password": passData.new_password,
            "confirm_new_password": passData.confirm_new_password
          });
          
          var config = {
            method: 'put',
            url: 'https://siapngajar-be-y2fugkloba-et.a.run.app/api/v1/users/change_password',
            headers: { 
              'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo1NzN9.1UfiJ7OTuLLWDegjp_fHFzdCKYouzjilcMWeYnSqLj4', 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data.meta.message));
            setCondition({
                ...condition, 
                modalMessage:response.data.meta.message,
                massageBackground:"green"
            })
          })
          .catch(function (error) {
            console.log(error);
            setCondition({
                ...condition, 
                modalMessage:error.response.data.meta.message,
                massageBackground:"red"
            })
          });
    }

    return (
        <div className='profilWrapper'>
            <Modal isOpen={condition.modalIsOpen} style={customStyles} onRequestClose={() => setCondition({...condition, modalIsOpen:false
            })}>
                <div className='modalAlign'>
                    <div style={{backgroundColor:condition.massageBackground}}>{condition.modalMessage}</div>
                    <form onChange={onChange}>
                    <div className="modalJudul">
                        Password Sekarang 
                    </div>
                    <input type="password" name = "current_password" value={passData.current_password}/>
                    <div className="modalJudul">
                        Password Baru
                    </div>
                    <input type="text" name = "new_password" value={passData.new_password}/>
                    <div className="modalJudul">
                        Ulangi Password Baru
                    </div>
                    <input type="text" name = "confirm_new_password" value={passData.confirm_new_password}/>
                    </form>
                </div>
                <div className='buttonAlign'>
                    <button className='modalButton' onClick={handleUpdateSent}>Kirim</button>
                    {/* <button className='modalButton' onClick={handleTest}>Test</button> */}
                </div>
            </Modal>
            <div className='profilJudul'>
                Profil Akun
            </div>
            <div>
                <div className='rowProfile'>
                    <div className='profil'>
                        {/* <div className='itemProfile'>
                        <div className='detailProfileHeader'>Sekolah</div>
                 </div> */}
                    </div>
                    <div className='columnProfile'>
                        <div className='itemProfile'>
                            <div className='detailProfileHeader'>Akun</div>
                        </div>
                    </div>
                    <div className='columnProfile'>
                        <div className='itemProfile'>
                            <div className='detailProfileHeader'>Sekolah</div>
                            <div className='detailProfileBody'>{login.school.school_name}</div>
                        </div>
                        <div className='itemProfile'>
                            <div className='detailProfileHeader'>Username</div>
                            <div className='detailProfileBody'>{login.username}</div>
                        </div>
                        <div className='itemProfile'>
                            <div className='detailProfileHeader'>Alamat Sekolah</div>
                            <div className='detailProfileBody2'>Jl. Panglima Sudirman No. 71 Caruban, Kronggahan, Mejayan, Kec. Mejayan, Kabupaten Madiun</div>
                        </div>
                    </div>
                </div>
                <div className='rowProfile'>
                    <div className='profil'>
                        {/* <div className='itemProfile'>
                        <div className='detailProfileHeader'>Sekolah</div>
                 </div> */}
                    </div>
                    <div className='columnProfile'>
                        <div className='itemProfile'>
                            <div className='detailProfileHeader'>Keamanan dan Privasi</div>
                        </div>
                    </div>
                    <div className='columnProfile'>
                        <div className='itemProfile'>
                            <div className='detailProfileHeader'>Password</div>
                            <div className='detailProfileBody' style={{border:"none" ,color:"#929191"}}>klik ‘Edit’ untuk mengganti password Anda</div>
                        </div>
                    </div>
                    <div className='columnProfile'>
                        <div className='itemProfile'>
                            <div className='detailProfileHeader' style={{cursor:"pointer"}} onClick={handleEditButton}>Edit</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfilAkun
